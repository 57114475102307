.advantages {
  margin: 40px 0 70px 0;

  @include media-breakpoint-up(xxl) {
    margin-top: 70px;
    margin-bottom: 180px;
  }

  &__content {
    position: relative;
  }

  &__img {
    width: 260px;
    top: 50%;
    left: 0;
    display: none;
    position: absolute;
    transform: translateY(-50%);

    @include media-breakpoint-up(lg) {
      display: block;
    }

    @include media-breakpoint-up(xxl) {
      width: 310px;
    }
  }

  &__inner {
    padding: 54px 0;
    color:  $white;
    background-color: $primary;
  }

  &__caption {
    &-title {
      margin-bottom: 30px;
    }

    &-list {
      li {
        font-size: 16px;
        color: $white;

        &::before {
          background-color: $white;
        }
      }
    }
  }

  &__contact {
    margin-top: 40px;

    @include media-breakpoint-up(xl) {
      margin-top: 0;
    }

    &-content {
      @include media-breakpoint-up(xxl) {
        padding-right: 30px;
      }
    }

    &-title {
      margin-bottom: 20px;
      line-height: 1.2;

      @include media-breakpoint-up(xl) {
        margin-bottom: 45px;
      }
    }

    &-icon {
      width: 55px;
      margin-right: 20px;

      @include media-breakpoint-up(xxl) {
        width: auto;
      }
    }

    &-subtitle {
      margin-bottom: 10px;
    }

    &-link {
      font-size: 20px;
      color: $white;
      display: inline-block;

      &:hover {
        color: $secondary;
      }

      @include media-breakpoint-up(lg) {
        font-size: 25px;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 33px;
      }
    }
  }
}