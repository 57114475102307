.material {
  padding: 70px 0;
  background: url('../images/material-bg.jpg') no-repeat center center;
  background-size: cover;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(xl) {
    padding: 100px 0;
  }

  @include media-breakpoint-up(xxl) {
    padding: 120px 0 140px 0;
  }

  &__content {
    max-width: 860px;
  }

  &__img {
    top: 120px;
    right: -18vw;
    display: none;
    position: absolute;

    @include media-breakpoint-up(lg) {
      display: block;
    }

    @include media-breakpoint-up(xxl) {
      top: 140px;
      right: -13vw;
    }

    &-inner {
      width: 50vw;

      @include media-breakpoint-up(xl) {
      }

      @include media-breakpoint-up(xxl) {
        max-width: 900px;
      }
    }
  }

  &__heading {
    margin-bottom: 50px;

    &-title {
      margin-bottom: 20px;
      color: $black;
    }

    &-text {
      * {
        margin: 0;
        font-size: 18px;
        line-height: 1.3;

        @include media-breakpoint-up(xl) {
          font-size: 20px;
        }
      }
    }
  }

  &__accordion {
    &-heading {
      margin-bottom: 25px;
      font-size: 25px;
      color: $primary;

      @include media-breakpoint-up(xl) {
        font-size: 30px;
      }
    }
  }

  &__bottom {
    margin-top: 30px;

    &-text {
      margin: 0;
    }
    
    &-icons {
      margin-top: 30px;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }

      @include media-breakpoint-up(xl) {
        padding-left: 15px;
      }
    }

    &-icon {
      height: 80px;
      margin-left: 15px;

      @include media-breakpoint-up(md) {
        height: auto;
      }
    }
  }
}