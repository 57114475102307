.process {
  &__col {
    width: 100%;
    max-width: 100%;
    margin-bottom: 30px;
    padding: 0 15px;
    flex: 0 0 auto;

    &:last-child {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
      width: 33.33333333%;
    }

    @include media-breakpoint-up(lg) {
      width: 20%;
    }
  }

  &__icon {
    width: 70px;

    @include media-breakpoint-up(xl) {
      width: auto;
    }
  }

  &__title {
    margin: 15px 0;
    font-size: 18px;
    color: $black;
    font-family: $primary-font;

    @include media-breakpoint-up(xl) {
      margin: 20px 0 25px 0;
      font-size: 20px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 24px;
    }
  }

  &__list {
    li {
      font-size: 16px;
    }
  }
}