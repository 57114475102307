// -----------------------------------------------------------------------------
// Style for buttons
// -----------------------------------------------------------------------------

.btn {
  padding: 18px 15px;
  font-size: 15px;
  font-weight: 900;
  line-height: 1.2;
  text-transform: uppercase;
  display: inline-block;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

  &--primary {
    color: $white;
    background-color: $primary;

    &:hover {
      color: $black;
      background-color: $secondary;
    }
  }

  &--secondary {
    color: $black;
    background-color: $secondary;
  }
}