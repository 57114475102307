// -----------------------------------------------------------------------------
// Normalization of HTML elements, manually forked from Normalize.css
// -----------------------------------------------------------------------------

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
}