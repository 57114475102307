.form {
  position: relative;

  * {
    font-family: $primary-font !important;    
  }

  &__group {
    margin-bottom: 20px;
  }

  &__control {
    width: 100%;
    padding: 14px ;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.3;
    color: $text;
    border: 1px solid #E7E7E7;
    background-color: $white;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    box-shadow: 0 0 10px 0 rgba($black, 0.1);
    transition: border-color 0.3s ease-in-out;

    &.error-label {
      border-color: $red;
    }

    &:focus {
      border-color: $primary;
    }

    @include input-placeholder {
      color: #868686;
    }

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  &__check {
    &-input {
      left: -9999px;
      margin: 0;
      position: absolute;

      &:checked+label {
        &::after {
          opacity: 1;
        }
      }
    }

    &-label {
      padding-left: 30px;
      font-size: 15px;
      color: #9E9E9E;
      line-height: 1.2;
      display: inline-block;
      position: relative;
      cursor: pointer;

      &::before,
      &::after {
        content: "";
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
      }

      &::before {
        width: 20px;
        height: 20px;
        left: 0;
        border: 1px solid #E7E7E7;
        background-color: $white;
        box-shadow: 0 0 10px 0 rgba($black, 0.1);
      }

      &::after {
        width: 10px;
        height: 10px;
        left: 5px;
        background-color: $primary;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }

      a {
        color: #9E9E9E;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  &__btn {
    min-width: 240px;
    margin-top: 10px;
    border: none;
    cursor: pointer;
  }

  label {
    &.error-label {
      padding-top: 8px;
      font-size: 14px;
      color: $red;
      display: block;
      cursor: pointer;
    }  
  }

  &__result {
    display: none;

    &--active {
      display: block;
      padding-top: 20px;
      font-weight: bold;
    }

    &--success {
      color: $primary;
    }

    &--error {
      color: #dc3545;
    }
  }

  &--loading {
    &:before {
      content: '';
      background: rgba(0, 0, 0, 0.1);
      position: absolute;
      z-index: 999;
      width: 100%;
      height: calc(100% + 30px);
      left: 0;
      top: -15px;
      box-sizing: border-box;
    }
  }
}