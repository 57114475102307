.works {
  margin-bottom: 60px;
  padding-top: 65px;
  background: url('../images/works-bg.jpg') no-repeat center top;
  background-size: auto;

  @include media-breakpoint-up(xxl) {
    background-position: center bottom;
    background-size: cover;
  }

  &__title {
    margin-bottom: 40px;
    color: $white;

    @include media-breakpoint-up(xl) {
      margin-bottom: 60px;
    }
  }

  &__col {
    margin-bottom: 30px;
  }

  &__item {
    background-color: #E7E7E7;

    &-img {
      width: 100%;
    }

    &-caption {
      height: 80px;
      padding: 10px;
      background-color: #E7E7E7;

      * {
        margin: 0;
        font-family: $secondary-font;
        font-size: 17px;
        font-weight: normal;
        color: #5C5C5C;
        line-height: 1.1;
      }
    }
  }
}