.accordion {
  &__item {
    margin-bottom: 8px;
    color: $white;
    background-color: $primary;

    &-title {
      padding: 14px 40px 14px 25px;
      font-family: $primary-font;
      font-weight: bold;
      cursor: pointer;
      position: relative;

      &::before {
        content: "";
        width: 10px;
        height: 10px;
        top: 18px;
        right: 20px;
        border: 1px solid $white;
        border-top: none;
        border-left: none;
        position: absolute;
        transform: rotate(45deg);
        transition: transform 0.3s ease-in-out;
      }
    }

    &-content {
      padding: 0 40px 25px 25px;
      display: none;

      * {
        margin: 0;
      }
    }

    &--open {
      .accordion {
        &__item {
          &-title {
            &::before {
              transform: rotate(-135deg);
            }
          }
        }
      }
    }
  }
}