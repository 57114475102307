// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// Fonts family
$primary-font: 'Roboto', sans-serif;
$secondary-font: 'Raleway', sans-serif;

// Colors
$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);
$primary: rgb(0, 69, 139);
$secondary: rgb(215, 223, 33);
$gray: rgb(189, 189, 189);
$red: rgb(220, 53, 69);
$text: rgb(51, 51, 51);

// Custom grid
$grid-gutter-width: 30px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px
);