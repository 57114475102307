.services {
  margin-top: 50px;
  margin-bottom: 30px;

  @include media-breakpoint-up(xl) {
    margin-top: 85px;
  }

  &__item {
    margin-bottom: 50px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 80px;
    }

    &-title {
      margin-top: 15px;
      margin-bottom: 20px;
      color: $primary;
      line-height: 1.2;
    }

    p {
      margin-top: 0;
      margin-bottom: 20px;
    }

    &-img {
      margin-top: 50px;

      @include media-breakpoint-up(xl) {
        margin-top: 80px;
      }
    }
  }
}