.method {
  margin: 75px 0;

  @include media-breakpoint-up(xl) {
    margin-top: 100px;
  }

  @include media-breakpoint-up(xxl) {
    margin-top: 150px;
  }

  &__caption {
    margin-bottom: 30px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 15px;
    }

    &-title {
      margin-bottom: 20px;
      color: $primary;
      line-height: 1.2;
    }

    &-text {
      margin: 0;
      font-size: 17px;
      line-height: 1.3;

      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }

      @include media-breakpoint-up(xl) {
        padding: 0 30px;
      }

      @include media-breakpoint-up(xxl) {
        padding: 0 55px;
        font-size: 20px;
      }
    }
  }

  &__info {
    &-heading {
      text-align: center;
      position: relative;

      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }

    &-title {
      padding-top: 30px;
      color: $primary;
      position: relative;
      z-index: 1;

      @include media-breakpoint-up(md) {
        padding-top: 80px;
      }

      @include media-breakpoint-up(xl) {
      }
    }

    &-icon {
      width: 70px;
      top: 55px;
      right: 15px;
      display: none;
      position: absolute;

      @include media-breakpoint-up(md) {
        display: block;
      }

      @include media-breakpoint-up(xxl) {
        width: 109px;
        top: 45px;
      }
    }

    &-list {
      margin-top: 25px;
      column-count: 2;
    }

    &-img {
      width: 280px;
      margin: 30px auto 0 auto;
      display: block;

      @include media-breakpoint-up(lg) {
        width: auto;
      }
    }

    &-btn {
      margin-top: 30px;

      @include media-breakpoint-up(xl) {
        margin-top: 20px;
      }
    }
  }
}