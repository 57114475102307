.header-menu {
  &__item {
    padding-right: 10px;

    &:last-child {
      padding-right: 0;
    }

    @include media-breakpoint-up(xl) {
      padding-right: 20px;
    }

    @include media-breakpoint-up(xxl) {
      padding-right: 40px;
    }
  }

  &__link {
    padding: 10px;
    font-size: 16px;
    color: $black;
    line-height: 1;
    text-transform: uppercase;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

    &:hover,
    &--active {
      color: $white;
      background-color: $primary;
    }

    @include media-breakpoint-up(xl) {
      font-size: 18px;
    }
  }
}