.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-bold {
  font-weight: bold;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.list-default {
  list-style: none;

  li {
    padding: 4px 0;
    font-size: 18px;
    font-weight: 300;
    color: $text;
    line-height: 1.2;

    &::before {
      content: "";
      width: 3px;
      height: 3px;
      margin-right: 5px;
      border-radius: 50%;
      background-color: $text;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.color-primary {
  color: $primary;
}