@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

.menu-mobile {
  width: 100%;
  height: 0%;
  top: 0;
  left: 0;
  background-color: $primary;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  z-index: 1040;
  transition: all 0.5s ease-in-out;

  &--open {
    height: 100%;
    opacity: 1;
    visibility: visible;

    .menu-mobile {
      &__wrapper {
        height: 100%;
      }

      &__item {
        animation: fadeInRight .6s ease-in-out forwards;
        animation-delay: .65s;
  
        &:nth-child(2) {
          animation-delay: .7s;
        }
  
        &:nth-child(3) {
          animation-delay: .75s;
        }
  
        &:nth-child(4) {
          animation-delay: .8s;
        }
  
        &:nth-child(5) {
          animation-delay: .85s;
        }
  
        &:nth-child(6) {
          animation-delay: .9s;
        }
  
        &:nth-child(7) {
          animation-delay: .95s;
        }
  
        &:nth-child(8) {
          animation-delay: 1s;
        }
  
        &:nth-child(9) {
          animation-delay: 1.05s;
        }
  
        &:nth-child(10) {
          animation-delay: 1.1s;
        }
      }
    }
  }

  & > .menu-mobile__overlay {
    height: 90%;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
  }

  &__wrapper {
    height: 0%;
    transition: all 0.5s ease-in-out;
  }

  &__list {
    padding: 0 15px;
  }

  &__item {
    padding: 15px 0;
    position: relative;
    opacity: 0;

    @include media-breakpoint-up(md) {
      padding: 20px 0;
    }

    &.current-menu-item {
      a {
        color: $white;

        &::before {
          width: 100%;
        }
      }
    }
  }

  &__link {
    padding-bottom: 10px;
    font-size: 1.6rem;
    color: $white;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    
    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }

    &::before {
      content: "";
      width: 0;
      height: 4px;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0 auto;
      background-color: $secondary;
      position: absolute;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      color: $white;

      &::before {
        width: 100%;
      }
    }
  }

  &__close {
    width: 50px;
    height: 50px;
    top: 20px;
    right: 20px;
    position: absolute;
    z-index: 1050;
    cursor: pointer;
    transition: all 0.45s ease-in-out;

    @include media-breakpoint-up(md) {
      top: 30px;
      right: 30px;
    }

    &:hover {
      .menu-mobile__close-bar {
        
        &:first-child {
          transform: rotate(-45deg);
        }

        &:nth-child(2) {
          transform: rotate(45deg);
        }
      }

      .menu-mobile__close-label {
        opacity: 1;
      }
    }
  }

  &__close-bar {
    width: 30px;
    height: 3px;
    top: 23px;
    right: 0;
    left: 0;
    margin: 0 auto;
    border-radius: 2px;
    background-color: $secondary;
    position: absolute;
    transition: all 0.45s ease-in-out;

    @include media-breakpoint-up(md) {
      width: 50px;
      height: 4px;
    }

    &:first-child {
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      transform: rotate(-45deg);
    }
  }

  &__close-label {
    right: 0;
    bottom: -10px;
    left: 0;
    font-size: 0.9rem;
    color: $white;
    position: absolute;
    opacity: 0;
    transition: all 0.45s ease-in-out;

    @include media-breakpoint-up(xl) {
      font-size: 1rem;
    }
  }

  .scrollbar-inner>.scroll-element.scroll-x {
    display: none;
  }

  .scrollbar-inner>.scroll-element .scroll-element_track {
    background-color: rgba($white, 0.3);
  }

  .scrollbar-inner>.scroll-element .scroll-bar {
    background-color: rgba($white, 0.5);
  }
}