.header {
  width: 100%;
  height: 80px;
  top: 0;
  left: 0%;
  background-color: $white;
  position: fixed;
  z-index: 1030;

  @include media-breakpoint-up(lg) {
    height: 100px;
  }

  &__logo {
    margin-right: 20px;

    &-img {
      width: 180px;

      @include media-breakpoint-up(lg) {
        width: 200px;
      }

      @include media-breakpoint-up(xxl) {
        width: 244px;
      }
    }
  }

  &--sticky {
    box-shadow: 0 0 15px 0 rgba($black, 0.1);
  }
}