// -----------------------------------------------------------------------------
// Basic typography style for copy text
// -----------------------------------------------------------------------------

p {
  margin-bottom: 15px;
  padding: 0;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.5;

  @include media-breakpoint-up(xl) {
    font-size: 16px;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-family: $secondary-font;
  font-weight: 300;
}

h1 {
  font-size: 30px;

  @include media-breakpoint-up(md) {
    font-size: 40px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 48px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 55px;
  }
}
h2 {
  font-size: 40px;

  @include media-breakpoint-up(lg) {
    font-size: 50px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 60px;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 70px;
  }
}
h3 {
  font-size: 35px;

  @include media-breakpoint-up(md) {
    font-size: 42px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 50px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 60px;
  }
}
h4 {
  font-size: 28px;

  @include media-breakpoint-up(lg) {
    font-size: 30px;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 35px;
  }
}
h5 {
  font-size: 20px;

  @include media-breakpoint-up(lg) {
    font-size: 25px;
  }
}
h6 {
  font-size: 18px;

  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }
}

a,
button,
.btn {
  transition: color 0.3s ease-in-out;
  text-decoration: none;
  
  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: transparent;
    box-shadow: none;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

*:focus {
  outline: transparent;
}