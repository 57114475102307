.hero {
  background: url('../images/hero-bg.jpg') no-repeat center center;
  background-size: cover;

  &__content {
    padding-top: 60px;
    padding-bottom: 60px;
    text-align: center;

    @include media-breakpoint-up(xl) {
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  &__caption {
    color: $white;

    &-title {
      font-weight: 600;
      line-height: 1.2;
    }

    &-subtitle {
      font-size: 20px;
      line-height: 1.4;

      @include media-breakpoint-up(md) {
        font-size: 26px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 30px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 39px;
      }
    }

    &-text {
      margin-top: 20px;
      margin-bottom: 0;
      font-family: 'Open Sans';
      font-size: 15px;

      @include media-breakpoint-up(md) {
        font-size: 16px;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 30px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 18px;
      }
    }
  }

  &__movies {
    margin: 30px 0 10px 0;

    @include media-breakpoint-up(md) {
      margin-top: 40px;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 60px;
    }

    &-item {
      margin: 0 10px 20px 10px;
    }

    &-img {
      width: 180px;

      @include media-breakpoint-up(lg) {
        width: 200px;
      }

      @include media-breakpoint-up(xl) {
        width: 245px;
      }
    }
  }

  &__btn {
    &:hover {
      background-color: $white;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 20px;
    }
  }
}